import React, { useEffect } from 'react';
import useLayout from 'hooks/useLayout';
import { connect } from 'react-redux';
import { Fragment } from 'react';

const LayoutFullpage = ({
  left,
  right,
  useLoading = true,
  authState: { loading }
}) => {
  useLayout();
  useEffect(() => {
    document.body.classList.add('h-100');
    const root = document.getElementById('root');
    if (root) {
      root.classList.add('h-100');
    }
    return () => {
      document.body.classList.remove('h-100');
      if (root) {
        root.classList.remove('h-100');
      }
    };
  }, []);

  return (
    <>
      {/* Background Start */}
      {/* <div className="fixed-background" /> */}
      {/* Background End */}

      <div className="container-fluid p-0 h-100 position-relative">
        {/* {useLoading && loading ? (
          <Fragment>
            <div className="loading" />
          </Fragment>
        ) : ( */}
          <Fragment>
            <div className="row g-0 h-100">
              {/* Left Side Start */}
              <div className="offset-0 col-12 d-none d-lg-flex col-lg h-lg-100 fixed-background">
                <div className="offset-md-1">{left}</div>
              </div>
              {/* Left Side End */}

              {/* Right Side Start */}
              <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                {right}
              </div>
              {/* Right Side End */}
            </div>
          </Fragment>
        {/* )} */}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    authState: state.auth
  };
}

export default connect(mapStateToProps)(LayoutFullpage);
